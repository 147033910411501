import * as React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import hero from '../../../images/issues/improve-quality-of-life/plan/2022-2023-pvd-quality-of-life-plan-hero.webp'

const IssuesImproveQualityOfLifePlanPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Issues - Improve Quality of Life - 2022-2023 PVD Quality of Life Plan" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
              className="w-full"
              src={hero}
              alt=""
            />
        </div>
        <div className="pt-10 max-w-5xl mx-auto">
          <h1 className="font-bold text-6xl text-center">Quality of Life Plan</h1>
          <h2 className="font-bold italic text-4xl text-center">A Plan To Improve Quality of Life in Providence</h2>

          <h3 className="font-bold text-3xl leading-relaxed">Press Release</h3>
          <p className="text-xl leading-relaxed">
            FOR IMMEDIATE RELEASE<br />
            July 21, 2022<br />
            Contact: <a href="mailto:press@johnforward1.com">press@johnforward1.com</a>
          </p>
          <h3 className="font-bold text-3xl text-center leading-relaxed">
            Councilman Goncalves Announces "Quality of Life" Plan
          </h3>
          <h4 className="font-bold italic text-2xl text-center leading-relaxed">
            A People-Powered Blueprint To Move Providence Forward 
          </h4>
          <p className="text-xl leading-relaxed">
            <strong>PROVIDENCE, RI</strong> &mdash; Councilman John Goncalves today released the "Quality of Life" plan, a comprehensive living document created with solutions from Providence residents to build a stronger, more resilient City.
          </p>
          <p className="text-xl leading-relaxed">
            The plan details more than 150 quality of life improvements and new, practical ideas to tackle ongoing challenges in the City. The health, wealth, and ability to enjoy life and thrive remains a top priority to Councilman Goncalves in the wards across the City, including his own, Ward 1. 
          </p>
          <p className="text-xl leading-relaxed">
            <strong>These are concrete, viable solutions &mdash; some of which have been proven effective in other municipalities &mdash; packed in a 40-page document that could start to be implemented in 2023 with the new Administration and City Council.</strong>
          </p>
          <p className="text-xl leading-relaxed">
            <a href="https://drive.google.com/file/d/1RjBceIrnLs59qcH8t3-HWHrMrZfuGM-E/view" target="_blank"><strong>&raquo; Read the Full Report Here</strong></a>
          </p>
          <p className="text-xl leading-relaxed">
            The plan, which also builds on the improvements implemented by the current Administration, is sectioned into focus areas.  They are: expanding and enhancing city services; addressing nuisance issues; transforming and improving education; addressing climate change and environmental justice; expanding housing and its affordability; improving public safety; strengthening the workforce and economic development; and elevating arts and culture. Participating residents also prioritized what was most important to them.
          </p>
          <p className="text-xl leading-relaxed">
            "Providence has the potential to continue to develop as a world-class city now and for future generations," Councilman Goncalves said. <strong>"Today, we are laying out a preliminary round of concrete, specific, and substantive ideas to ensure we can move Providence forward. The best, bold, and ambitious ideas come from the people, and I truly believe the people closest to the problems are closest to the solutions.</strong> I want to thank the more than 500 Providence residents who have contributed to this report so far."
          </p>
          <p className="text-xl leading-relaxed">
            Ideas within the plan include: 
          </p>
          <ul className="pl-8 text-xl leading-relaxed list-disc">
            <li>
                Expanding metrics and using analytics track the performance of the Department of Public Works in real time
            </li>
            <li>
                Creating a continually updated public score for each city department to ensure top-of-the-line customer service
            </li>
            <li>
                Eliminating complacency and mandate efficiency on potholes, sidewalks, street sweeping, trash collection, snow removal, etc.
            </li>
            <li>
                Supporting density in low-income zip codes and increasing housing affordability/stock in these areas by reforming zoning laws 
            </li>
            <li>
                Taking a more active role in turning around Providence Public Schools by increasing investment in early childhood education, comprehensive services, and access to wrap-around services</li>
            <li>
                Reducing the city's greenhouse gas emissions by pushing towards carbon neutrality by 2040 and revitalizing the Port of Providence 
            </li>
            <li>
                Advocating to quadruple the size of The Providence Graffiti Task Force and the most robust and proactive Anti-Graffiti and "Clean Providence Program" to ramp up graffiti abatement initiatives &amp; more
            </li>
          </ul>
          <p className="text-xl leading-relaxed">
            Additional residents are encouraged to read the report and share their priorities and ideas by taking the survey that led to this plan. They can do so by going to the Councilman's website at: <a href="/qualityoflifeplan">https://www.johnforward1.com/qualityoflifeplan</a>. A summary document can also be found there.
          </p>
          <p className="text-xl leading-relaxed">
            "This is just a start," Goncalves said. "We're intentionally releasing this as a living document to continue to crowdsource ideas and solutions. This is what will set the bar for taking our City services to the next level while demanding high expectations of our government and the best for all."
          </p>
          <p className="text-xl leading-relaxed">
            Councilman Goncalves looks forward to working with his future Council colleagues, community partners, and the next Mayor to bring these ideas to fruition, either administratively with budget shifts or legislatively with amended or new laws.
          </p>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                <div>
                    <h3 className="font-bold text-3xl leading-relaxed">Brief Summary <span className="text-xl">(<a href="https://docs.google.com/document/d/e/2PACX-1vTpVV7Mqyj67B4PEFTsFw51KmDZKli-6RBUUJaLlDDqdZAIUUs6m5qrw7zmc5qshw/pub" target="_blank">view full-size doc</a>)</span></h3>
                    <iframe className="border" src="https://docs.google.com/document/d/e/2PACX-1vTpVV7Mqyj67B4PEFTsFw51KmDZKli-6RBUUJaLlDDqdZAIUUs6m5qrw7zmc5qshw/pub?embedded=true"  width="100%" height="702" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                </div>
                <div>
                    <h3 className="font-bold text-3xl leading-relaxed">Full Plan <span className="text-xl">(<a href="https://drive.google.com/file/d/1RjBceIrnLs59qcH8t3-HWHrMrZfuGM-E/view" target="_blank">view full-size PDF</a>)</span></h3>
                    <iframe className="border" src="https://drive.google.com/file/d/1RjBceIrnLs59qcH8t3-HWHrMrZfuGM-E/preview" width="100%" height="702" frameborder="0" marginheight="0" marginwidth="0"></iframe>
                    <p className="text-base text-center">Click arrow in upper right-hand corner to view full-size PDF</p>
                </div>
            </div>

            <h3 className="font-bold text-center text-3xl leading-relaxed">Additional Thoughts or Ideas?</h3>
            <p className="text-center text-xl leading-relaxed">Once you've read the Quality of Life plan, please consider being a part of the solution by submitting your feedback to this iterative living document.</p>
            <div className="mt-5 text-center">
                <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdBOORbNOWV-fwQ2CTyQtjE5F9PfH7zwDfQ2l3oZKd6OIijgQ/viewform"
                target="_blank"
                className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-4xl font-medium font-bold text-white bg-blue-buttonLight hover:bg-blue-700"
                >
                Fill out this survey to submit your feedback
                </a>
            </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IssuesImproveQualityOfLifePlanPage
